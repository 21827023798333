import { autoinject } from 'aurelia-framework';
import {
  ValidationRules,
  ValidationControllerFactory,
  ValidationController,
  validationMessages,
} from 'aurelia-validation';
import { ProfileRes, UserSessionSimple } from 'models/api';
import AuthService from 'services/auth-service';
import UserService from 'services/user-service';
import { observable } from 'aurelia-framework';
import { ErrorService } from 'services/error-service';
import { NotificationsService } from 'services/notifications-service';
import { ModalService } from 'services/modal-service';
import { HttpClient } from 'aurelia-fetch-client';
import M from 'minimatch';
import { DialogService } from 'aurelia-dialog';
import { ConfirmEmailModal } from 'components/profile/confirm-email-modal';

@autoinject
export class Profile {
  private profile: any;
  sessions: UserSessionSimple[];
  private subscriptions: any;
  private editMode: boolean = false;
  private validationController: ValidationController;
  private postal: number;
  private region: string;

  private loading: boolean = false;

  protected http: any = HttpClient;

  newPassword: string;
  newPasswordConfirm: string;
  passwordError: string;

  newEmail: string;
  emailError: string;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private validationControllerFactory: ValidationControllerFactory,
    private errorService: ErrorService,
    private notificationsService: NotificationsService,
    private modalService: ModalService,
    private dialogService: DialogService
  ) {
    const http = new HttpClient();
    this.http = http;
    this.validationController =
      validationControllerFactory.createForCurrentScope();

    this.http.configure((config) => {
      config.withBaseUrl('https://webapi.no/api/v1/zipcode/').withDefaults({
        credentials: 'same-origin',
      });
    });
  }

  async attached() {
    await this.getData();
    await this.validation();
    this.region = this.profile.region;
  }

  getIcon(os: string) {
    if (os.includes('iOS') || os.includes('Android')) {
      return 'phone';
    }
    return 'computer';
  }

  async getData() {
    this.loading = true;

    try {
      this.profile = await this.userService.getUser();
      this.sessions = await this.userService.getSessions();
      this.loading = false;
    } catch (error) {
      this.errorService.handleError(error);
      this.loading = false;
    }
  }

  async updateUserData() {
    try {
      await this.userService.updateUser({
        firstName: this.profile.firstName,
        lastName: this.profile.lastName,
        phone: this.profile.phone.replace(/\s/g, ''),
      });
      this.notificationsService.success(
        'Profil lagret',
        'Profilen din er nå lagret.'
      );
      this.toggleEdit();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private toggleEdit() {
    this.editMode = !this.editMode;
  }

  async validation() {
    ValidationRules.ensure((it: any) => it.firstName)
      .required()
      .withMessage('Fornavn er påkrevd')

      .ensure((it: any) => it.lastName)
      .required()
      .withMessage('Etternavn er påkrevd')

      .ensure((it: any) => it.phone)
      .required()
      .withMessage('Telefonnummer er påkrevd')
      .then()
      .matches(/\d{8}$/)
      .withMessage('Ugyldig telefonnumer')

      .on(this.profile);
  }

  async validateAndSave() {
    const { valid } = await this.validationController.validate();
    if (!valid) {
      return;
    }
    this.updateUserData();
  }

  async removeDevice(deviceId: string, currentDevice: boolean) {
    if (currentDevice) {
      const confirmRemoval = await this.modalService.confirmDanger({
        title: 'Fjern gjeldende pålogget enhet',
        body: 'Er du sikker på at du vil fjerne denne enheten? Du vil bli logget ut.',
        confirmButtonText: 'Fjern enhet og logg ut',
      });

      if (!confirmRemoval) {
        return;
      }

      try {
        await this.userService.removeSession(deviceId);

        this.getData();
        this.notificationsService.success(
          'Innlogging fjernet',
          'Enheten er nå fjernet og logget ut'
        );
      } catch (error) {
        this.errorService.handleError(error);
      }
    } else {
      try {
        await this.userService.removeSession(deviceId);

        this.getData();
        this.notificationsService.success(
          'Innlogging fjernet',
          'Enheten er nå fjernet og logget ut'
        );
      } catch (error) {
        this.errorService.handleError(error);
      }
    }
  }

  async getPostal(postalCode) {
    if (postalCode.length === 4) {
      const res = await this.http.fetch(postalCode);

      if (res.ok) {
        const data = await res.json();

        this.region = data.data.city;
      } else {
        this.region = '';
      }
    } else {
      this.region = '';
    }
  }

  cancelEdit() {
    this.toggleEdit();
    this.getData();
  }

  async updatePassword() {
    if (this.newPassword !== this.newPasswordConfirm) {
      this.passwordError = 'Passordene er ikke like';
      return;
    }

    this.passwordError = null;

    try {
      await this.authService.updatePassword(this.newPassword);
      this.newPassword = '';
      this.newPasswordConfirm = '';
      this.notificationsService.success(
        'Passord endret',
        'Passordet ditt er nå endret'
      );
    } catch (error) {
      if (error?.response?.data?.message) {
        const { message } = error.response.data;
        this.passwordError = Array.isArray(message) ? message[0] : message;
      } else {
        this.errorService.handleError(error);
      }
    }
  }

  async updateEmail() {
    if (!this.newEmail) {
      return;
    }

    if (this.newEmail?.toLowerCase() === this.profile.email.toLowerCase()) {
      this.emailError = 'E-posten kan ikke være den samme som nåværende';
      return;
    }

    try {
      const response = await this.authService.sendVerifyCode(this.newEmail);
      if (response?.success === true) {
        this.emailError = null;
        this.dialogService
          .open({
            viewModel: ConfirmEmailModal,
            model: {
              newEmail: this.newEmail,
            },
          })
          .whenClosed((res) => {
            if (!res.wasCancelled) {
              this.getData();
              this.notificationsService.success(
                'E-post oppdatert',
                'Din e-post er nå bekreftet og oppdatert'
              );
            }
          });
      } else {
        this.emailError = response.message;
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        const { message } = error.response.data;
        this.emailError = Array.isArray(message) ? message[0] : message;
      } else {
        this.errorService.handleError(error);
      }
    }
  }
}
