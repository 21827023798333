import AuthService from 'services/auth-service';
import { Router } from 'aurelia-router';
import { LoginClientParams } from './../../models/custom/login';
import { autoinject } from 'aurelia-framework';
import ClientService from 'services/client-service';
import { ClientInfo } from 'models/api/models/ClientInfo';
import { ErrorService } from 'services/error-service';
@autoinject
export class LoginClient {
  private clientInfo: ClientInfo;
  private loading: boolean = true;
  private clientSlug: string;
  private needClientApproval: boolean = false;

  constructor(
    private router: Router,
    private clientService: ClientService,
    private authService: AuthService,
    private errorService: ErrorService
  ) {}

  activate(params: LoginClientParams) {
    this.handleLogin(params);
  }

  async handleLogin(params: LoginClientParams) {
    if (!params.clientSlug) {
      this.router.navigateToRoute('login');
    }

    if (params.returnUrl) {
      localStorage.setItem('loginClientReturnUrl', params.returnUrl);
    }

    this.clientSlug = params.clientSlug;
    this.clientInfo = await this.clientService.getClientInfo(params.clientSlug);

    this.loginToClient();
  }

  async loginToClient(approval: boolean = null) {
    this.loading = true;
    this.needClientApproval = false;

    try {
      const { redirectUrl, clientRelation } =
        await this.authService.loginClient({
          clientSlug: this.clientSlug,
          approval: approval === true ? approval : undefined,
        });

      if (clientRelation === false) {
        this.needClientApproval = true;
        return;
      }

      setTimeout(() => {
        const returnUrl = localStorage.getItem('loginClientReturnUrl');
        if (returnUrl) {
          localStorage.removeItem('loginClientReturnUrl');
          window.location.href = `${redirectUrl}&returnUrl=${returnUrl}`;
        } else {
          window.location.href = redirectUrl;
        }
      }, 1000);
    } catch (error) {
      if (error?.response?.data?.clientRelation === false) {
        this.needClientApproval = true;
      } else {
        this.errorService.handleError(error);
      }
    }
  }
}
