import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { LoginCallbackParams } from 'models/custom/login';
import AuthService from 'services/auth-service';

@autoinject
export class LoginCallback {
  constructor(private router: Router, private authService: AuthService) {}

  activate(params: LoginCallbackParams) {
    this.handleCallback(params);
  }

  async handleCallback({ authToken, errorCode, errorMessage }: LoginCallbackParams) {
    // Empty callback - return to login
    if (!authToken && !errorCode && !errorMessage) {
      this.router.navigateToRoute('login');
      return;
    }

    // Check for errors
    if (!authToken) {
      this.router.navigateToRoute('login', { errorCode, errorMessage });
      return;
    }

    try {
      const tokens = await this.authService.getAccessTokens(authToken);
      localStorage.setItem('aboIdUser:loggedIn', 'true');

      const loginRedirect = localStorage.getItem('loginRedirect');
      if (loginRedirect) {
        this.router.navigate(loginRedirect);
        localStorage.removeItem('loginRedirect');
        return;
      }

      // Navigate to home
      this.router.navigateToRoute('home');
    } catch (error) {}
  }
}
