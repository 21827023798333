import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { LoginCallbackParams } from 'models/custom/login';
import AuthService from 'services/auth-service';

@autoinject
export class PasswordlessCallback {
  constructor(private router: Router, private authService: AuthService) {}

  activate(params) {
    this.handleCallback(params);
  }

  async handleCallback({ token, errorCode, errorMessage }) {
    // Empty callback - return to login
    if (!token && !errorCode && !errorMessage) {
      this.router.navigateToRoute('login');
      return;
    }

    // Check for stored email
    const storedEmail = localStorage.getItem('passwordlessEmail');
    if (!storedEmail) {
      // TODO: Ask for email if not stored
      // email = window.prompt('Please provide your email for confirmation');

      // TODO: Handle error
      this.router.navigateToRoute('login', {
        errorMessage: 'Kunne ikke logge inn',
      });
      return;
    }

    // Check for errors
    if (!token) {
      // TODO: Handle error - show message and redirect to login
      return;
    }

    try {
      const res = await this.authService.getAccessTokensWithPasswordless(token, storedEmail);
      localStorage.setItem('aboIdUser:loggedIn', 'true');

      const loginRedirect = localStorage.getItem('loginRedirect');
      if (loginRedirect) {
        localStorage.removeItem('loginRedirect');
        setTimeout(() => {
          this.router.navigate(loginRedirect);
        });
      } else {
        setTimeout(() => {
          this.router.navigateToRoute('home');
        });
      }
    } catch (error) {
      console.error(error);
      const errorMessage = error?.response?.data?.message;

      console.log(errorMessage);
      console.log(error?.response?.data);
      // TODO: Send error
      setTimeout(() => {
        this.router.navigateToRoute('login', { errorMessage });
      });
    }
  }
}
