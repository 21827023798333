import ClientService from 'services/client-service';
import { CreateUserDto } from './../../models/api/models/CreateUserDto';
import { LoginParams } from './../../models/custom/login';
import { autoinject } from 'aurelia-framework';
import { Router, Redirect } from 'aurelia-router';
import AuthService from 'services/auth-service';
import { ErrorService } from 'services/error-service';
import environment from '../../../config/environment.json';
import LocalService from 'services/local-service';
import UserService from 'services/user-service';
import {
  ValidationController,
  ValidationControllerFactory,
  ValidationRules,
} from 'aurelia-validation';
import { NotificationsService } from 'services/notifications-service';
import { ClientInfo } from 'models/api';

@autoinject
export class Login {
  errorCode: string;
  errorMessage: string;
  clientSlug: string;
  logoUrl: string;
  backgroundUrl: string;
  email: string;

  showNewForm: boolean = false;

  validationController: ValidationController;
  validationRules: any;

  dto: {
    email: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
    password?: string;
    emailVerifyCode?: string;
  };

  acceptedTerms: boolean = false;
  isLoading: boolean = null;

  firstNameRef: HTMLInputElement;
  passwordRef: HTMLInputElement;

  clientInfo: ClientInfo;
  userExist: boolean;

  showVerifyEmailForm: boolean = false;
  codeInputEl: HTMLInputElement;
  codeInput: string;
  newVerifyEmailSent: boolean = false;

  constructor(
    private authService: AuthService,
    private errorService: ErrorService,
    private router: Router,
    private localService: LocalService,
    private userService: UserService,
    private validationControllerFactory: ValidationControllerFactory,
    private clientService: ClientService,
    private notificationsService: NotificationsService
  ) {
    this.validationController =
      validationControllerFactory.createForCurrentScope();
  }

  async canActivate({ clientSlug, returnUrl }) {
    if (localStorage.getItem('aboIdUser:loggedIn')) {
      return new Redirect('/');
    }

    if (clientSlug) {
      const verifyClient = await this.verifyAndGetClient(clientSlug);
      if (!verifyClient) {
        return new Redirect('/');
      } else {
        this.clientInfo = verifyClient;
      }
    }
  }

  activate(params: LoginParams) {
    // TODO: Check if user is already logged in?
    this.errorCode = params.errorCode;

    if (this.errorCode) {
      this.errorMessage = this.errorService.getErrorMessage(params.errorCode);
    } else if (params.errorMessage) {
      this.errorMessage = params.errorMessage;
    }

    if (params.clientSlug) {
      // this.verifyClient(params.clientSlug);
      this.clientSlug = params.clientSlug;
      this.logoUrl = `${environment.apiBaseUrl}/clients/logo/${params.clientSlug}`;
      this.backgroundUrl = `${environment.apiBaseUrl}/clients/background/${params.clientSlug}`;
    }

    this.dto = {
      email: params.email ? decodeURIComponent(params.email) : null,
      firstName: null,
      lastName: null,
      phone: null,
      password: null,
    };

    this.setupValidation();

    if (params.email) {
      this.checkUserExist();
    }
  }

  async setupValidation() {
    ValidationRules.ensure((it: any) => it.email)
      .required()
      .withMessage('E-post er påkrevd')
      .email()
      .withMessage('Ugyldig e-postadresse')

      .ensure((it: any) => it.firstName)
      .required()
      .when((dto) => this.showNewForm)
      .withMessage('Fornavn er påkrevd')

      .ensure((it: any) => it.lastName)
      .required()
      .when((dto) => this.showNewForm)
      .withMessage('Etternavn er påkrevd')

      .ensure((it: any) => it.phone)
      .required()
      .when((dto) => this.showNewForm)
      .withMessage('Telefonnummer er påkrevd')
      .then()
      .matches(/\d{8}$/)
      .withMessage('Ugyldig telefonnumer')
      .then()
      .satisfies(async (value: string, object?: any) => {
        try {
          const userExist = await this.userService.checkPhoneExist(value);
          if (userExist.success === true) {
            return true;
          } else {
            return false;
          }
        } catch (error) {
          this.notificationsService.error(
            'En feil har oppstått',
            'Prøv igjen senere'
          );
          return false;
        }
      })
      .withMessage('Telefonnummeret er allerede i bruk eller ugyldig')

      .ensure((it: any) => it.password)
      .required()
      .when((dto) => this.showNewForm)
      .withMessage('Passord er påkrevd')

      .ensure((it: any) => it.emailVerifyCode)
      .required()
      .withMessage('Kode er påkrevd')
      .when((dto) => this.showVerifyEmailForm)
      .then()
      .minLength(6)
      .withMessage('Ugyldig kode')
      .then()
      .maxLength(6)
      .withMessage('Ugyldig kode')

      .on(this.dto);
  }

  async checkUserExist() {
    try {
      this.errorMessage = null;
      const userExistRes: any = await this.authService.checkUserExists(
        this.dto.email
      );

      this.userExist = userExistRes.userExist;

      if (this.userExist) {
        this.showNewForm = false;
      } else {
        this.showNewForm = true;
      }

      setTimeout(() => {
        this.passwordRef?.focus();
      });
    } catch (error) {
      if (!error.response) {
        this.errorMessage =
          'aboID er ikke tilgjengelig akkurat nå. Prøv igjen senere.';
      } else {
        this.errorService.handleError(error);
      }
    }
  }

  async verifyAndGetClient(clientSlug: string) {
    try {
      const clientInfo = await this.clientService.getClientInfo(clientSlug);
      return clientInfo;
    } catch (error) {
      return null;
    }
  }

  async loginWithVipps() {
    const url = `${environment.apiBaseUrl}/auth/user/vipps`;
    window.location.href = url;
  }

  async loginCredentials() {
    const { valid } = await this.validationController.validate();
    if (!valid) {
      return;
    }

    this.isLoading = true;

    try {
      const response = await this.authService.loginCredentials(
        this.dto.email,
        this.dto.password
      );
      // Create new user form
      if (response?.success === true) {
        localStorage.setItem('aboIdUser:loggedIn', 'true');
        this.isLoading = false;

        if (response?.oldestRemoved) {
          // TODO: Modal
          this.notificationsService.success(
            'Maks antall enheter nådd',
            'Du kan logge inn på maks 5 enheter. Den eldste enheten er nå logget ut.'
          );
        }

        const loginRedirect = localStorage.getItem('loginRedirect');
        if (loginRedirect) {
          localStorage.removeItem('loginRedirect');
          setTimeout(() => {
            this.router.navigate(loginRedirect);
          });
        } else {
          setTimeout(() => {
            this.router.navigateToRoute('home');
          });
        }
      } else {
        this.isLoading = false;
        this.errorMessage = response.message;
      }
    } catch (error) {
      this.isLoading = false;
      if (error?.response?.data?.message) {
        if (error?.response?.data?.forceResetPassword) {
          this.errorMessage =
            error.response.data.message +
            ' Bruk "Glemt passord" for å opprette nytt passord.';
        } else {
          this.errorMessage = error.response.data.message;
        }
      } else {
        this.errorService.handleError(error);
      }
    }
  }

  async requestVerifyEmail(retry: boolean = false) {
    this.showVerifyEmailForm = true;
    this.showNewForm = false;

    if (retry) {
      this.newVerifyEmailSent = true;
    }

    try {
      const response = await this.authService.sendVerifyCode(this.dto.email);
      if (response?.success === true) {
        this.showVerifyEmailForm = true;
        this.codeInputEl?.focus();
      } else {
        this.errorMessage = response.message;
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  async createAccount() {
    if (this.acceptedTerms !== true) {
      return;
    }

    const { valid } = await this.validationController.validate();
    if (!valid) {
      return;
    }

    this.isLoading = true;

    // Create user
    try {
      const userDto: CreateUserDto = {
        email: this.dto.email,
        firstName: this.dto.firstName,
        lastName: this.dto.lastName,
        phone: this.dto.phone,
        password: this.dto.password,
        emailVerifyCode: this.dto.emailVerifyCode,
        clientSlug: this.clientSlug,
      };

      // Create user
      const createRes = await this.authService.post('user', userDto);
      if (createRes?.success) {
        localStorage.setItem('aboIdUser:loggedIn', 'true');
        this.isLoading = false;

        const loginRedirect = localStorage.getItem('loginRedirect');
        if (loginRedirect) {
          localStorage.removeItem('loginRedirect');
          setTimeout(() => {
            this.router.navigate(loginRedirect);
          });
        } else {
          setTimeout(() => {
            this.router.navigateToRoute('home');
          });
        }
      }
    } catch (error) {
      this.isLoading = false;
      if (error?.response?.data?.message) {
        const { message } = error.response.data;
        this.errorMessage = Array.isArray(message) ? message[0] : message;
      } else {
        this.errorService.handleError(error);
      }
    }
  }

  cancel() {
    this.dto = {
      email: this.dto.email,
    };

    this.showNewForm = false;
  }
}
